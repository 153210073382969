import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const KundenEventHamburgPage = () => {
  return (
    <Layout pageTitle="KundeneventHamburg" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Event Review - 28. Juni & 29. Juni 2022</p> 
                <h1>TIE Talk Kundenevent/Anwendertagung, the i-engineers in Hamburg</h1>
                <p className="text-medium">
                
                «Welche Fragestellungen löst ihr in eurem Krankenhaus mit der health-engine?» </p>
                <p className="text-medium">
                Auf unserem Event in Hamburg konnten sich Anwender, und solche die es werden wollen, über die Möglichkeiten der health-engine informieren, sich über die konkreten Umsetzungsprojekte in unterschiedlichen
                Krankenhäusern austauschen und sich untereinander vernetzen.
                </p>

                <p>Ein besonderer Dank geht dabei an Andreas Rygol, Leitung Konzernbereich Informationstechnologie und Medizintechnik Immanuel Albertinen Diakonie, für seine Keynote und die Einblicke in die Projekte rund um die Nutzung der health-engine.
                </p>

                <h3 style={{ marginBottom: 4, marginTop: 30 }} className="italic">«Wir sind sehr zufrieden mit dem Event: Die Kunden haben ihre Erfahrungen und ihr Wissen ausgetauscht, neue Nutzungsmöglichkeiten kennengelernt und sich untereinander weiter vernetzt»</h3>
                <div style={{ fontSize: 15, marginBottom: 30  }} className="italic">- resümiert unser Geschäftsführer Volker Sobieroy </div>
                
                <p>Die Teilnehmer hatten viel Gesprächsstoff für spannende Diskussionen. Besonders interessant ist auch das Interview des <a href="https://www.krankenhaus-it.de" target="_blank" rel="noreferrer" >Krankenhaus-IT Journal</a> mit Andreas Rygol.</p>
                <div className={buttonGroup}><a href="https://www.tie.ch/downloads/anwendertagung-22.pdf" className="button-outline blue" target="_blank" rel="noreferrer"> Zum Artikel </a> </div>
              
                 <h3 style={{ marginTop: 30 }}>Interview mit Andreas Rygol - Immanuel Albertinen Diakonie</h3>
                 <a
                  href="https://www.youtube.com/watch?v=ZkFEgx0OW9A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/Interview-Video-Hero-Andreas-vs1.jpg"
                    alt="Interview mit Andreas Rygol"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>

                 <h3  style={{ marginTop: 60 }}>Interview mit Volker Sobieroy, the i-engineers GmbH</h3>
                 <a
                  href="https://www.youtube.com/watch?v=N_v8lviqB28&t=27s"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/Interview-Video-Hero-Volker-vs1.jpg"
                    alt="Interview mit Volker Sobieroy"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>

              
              
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default KundenEventHamburgPage;
